<app-header></app-header>
<div *ngIf="post" class="wrapper">
  <div class="container pb-5">
    <div class="text-center">
      <h2 class="mb-5 color-primary">{{post.title}}</h2>
      <img *ngIf="post.feature_image" class="post-image" src="{{post.feature_image}}" alt="{{post.title}}"/>
    </div>
    <div class="row post">
      <div class="col-sm-2 col-6 mt-4 fw-bold">
        <div *ngFor="let tag of post.tags" class="fw-bolder">{{tag.name | uppercase}}</div>
<!--        <small class="text-muted d-block">Last updated {{post.updated_at | date:'mediumDate'}}</small>-->
        <div *ngFor="let author of post.authors" class="fst-italic">{{author.name}}</div>
      </div>
      <div class="col-10">
        <div [innerHTML]="post.html"></div>
      </div>
    </div>
  </div>
</div>
