import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { DecisionResponse } from '../model/decision-response.interface';

@Injectable()
export class DecisionsService {
  baseUrl = `${window.location.origin}/api/decisions/`;

  constructor(private _http: HttpClient) {
  }

  getDecisionsPageable(page: number, size: number) {
    return this._http.get<DecisionResponse>(`${this.baseUrl}findByFilters?page=${page}&size=${size}`);
  }

  getDecisionsFiltered(page: number, size: number, includeContent: boolean, filterParams: any) {
    return this._http.get<DecisionResponse>(`${this.baseUrl}findByFilters?page=${page}&size=${size}&includeContent=${includeContent}&filterParams=${filterParams}`);
  }

  getDecisionsByContent(content: string, page: number, size: number, includeContent: boolean) {
    return this._http.get<DecisionResponse>(`${this.baseUrl}findByContent?page=${page}&size=${size}&content=${content}&includeContent=${includeContent}`);
  }

  downloadDecisionByFilename(filename: string) {
    return this._http.get(`${this.baseUrl}attachments/download?filename=${filename}`, {responseType: 'blob'});
  }

}
