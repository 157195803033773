import {Component, OnInit} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AdministrativeCourtDecisionResponse } from '../../model/administrative-court-decision-response.interface';
import { AdministrativeCourtDecision } from '../../model/administrative-court-decision.interface';
import { AppealResponse } from '../../model/appeal-response.interface';
import { Appeal } from '../../model/appeal.interface';
import { DecisionResponse } from '../../model/decision-response.interface';
import { Decision } from '../../model/decision.interface';
import { HighAdministrativeCourtDecisionResponse } from '../../model/high-administrative-court-decision-response.interface';
import { HighAdministrativeCourtDecision } from '../../model/high-administrative-court-decision.interface';
import {Tag} from '../../model/tag.interface';
import { AdministrativeCourtDecisionService } from '../../services/administrative-court-decisions.service';
import { AppealsService } from '../../services/appeals.service';
import {ContentService} from '../../services/content.service';
import {tap} from 'rxjs/operators';
import {Post} from '../../model/post.interface';
import { DecisionsService } from '../../services/decisions.service';
import { HighAdministrativeCourtDecisionService } from '../../services/high-administrative-court-decisions.service';
import {StorageService} from '../../services/storage.service';
import table from '../../../assets/tables/table.json';
import odlukiUS from '../../../assets/tables/odlukiUS.json';
import odlukiVUS from '../../../assets/tables/odlukiVUS.json';
import zalbigrupirani from '../../../assets/tables/zalbi-grupirani.json';

@Component({
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss']
})
export class HomePage implements OnInit {
  tags: Tag[];
  homePost: Post;
  newsPosts: Post[];
  newsTag: Tag;
  refTags: Tag[];
  tabs1 = 1;
  tabs2 = 2;
  languages = ["MK", "AL", "EN"]
  selectedLanguage : string = "MK";
  table = table;
  odlukiUS = odlukiUS;
  odlukiVUS = odlukiVUS;
  zalbigrupirani = zalbigrupirani;
  zalbi: FormGroup;
  decisions: Decision[];
  appeals: Appeal[];
  acd: AdministrativeCourtDecision[];
  hacd: HighAdministrativeCourtDecision[];

  page = 0;
  size = 5;

  constructor(private service: ContentService,
              private storage: StorageService,
              private _decisionService: DecisionsService,
              private _appealsService: AppealsService,
              private _administrativeCourtDecisionService: AdministrativeCourtDecisionService,
              private _highAdministrativeCourtDecisionService: HighAdministrativeCourtDecisionService,
              private router: Router) {
  }

  ngOnInit() {
    this.selectedLanguage = this.storage.getLanguage();
    this.getTags(this.selectedLanguage);
    this.getHomePost(this.selectedLanguage);
    this.getRefTags();
    this.getNewsPosts(this.selectedLanguage);

    this._decisionService.getDecisionsPageable(this.page, this.size)
      .subscribe((decisionResponse: DecisionResponse) => {
        this.decisions = decisionResponse.content;
      })

    this._appealsService.getAppealsPageable(this.page, this.size)
      .subscribe((appealResponse: AppealResponse) => {
        this.appeals = appealResponse.content;
      })

    this._administrativeCourtDecisionService.getAdministrativeCourtDecisionPageable(this.page, this.size)
      .subscribe((acdResponse: AdministrativeCourtDecisionResponse) => {
        this.acd = acdResponse.content;
      })

    this._highAdministrativeCourtDecisionService.getHighAdministrativeCourtDecisionPageable(this.page, this.size)
      .subscribe((hacdResponse: HighAdministrativeCourtDecisionResponse) => {
        this.hacd = hacdResponse.content;
      })

    this.zalbi = new FormGroup({
      datum: new FormControl(''),
      zalitel: new FormControl(''),
      dogovorenorgan: new FormControl(''),
      postapka: new FormControl('')
    });
  }

  getTags(selectedLang) {
    this.service.getAllTags()
      .pipe(
        tap((data: any) => {
          this.tags = data.tags
            .filter(tag => !tag.slug.includes('_') && tag.slug.startsWith(selectedLang.toLowerCase()+"-"))
            .sort((a,b) => (parseInt(a.description) > parseInt(b.description)) ? 1 : ((parseInt(b.description) > parseInt(a.description)) ? -1 : 0));
          this.newsTag = data.tags.filter(tag => tag.slug.includes('novosti'))[0];
        })
      ).subscribe();
  }

  getHomePost(selectedLanguage) {
    this.service.getPostBySlug(`${selectedLanguage}-naslovna`, selectedLanguage)
      .pipe(
        tap(data => {
          this.homePost = data.posts[0];
        })
      ).subscribe(_ => console.log());
  }

  getRefTags() {
    this.service.getAllTags()
      .pipe(
        tap((data: any) => {
          this.refTags = data.tags
            .filter(tag => tag.slug == `${this.selectedLanguage.toLowerCase()}-registar-na-predmeti` || tag.slug == `${this.selectedLanguage.toLowerCase()}-pravna-ramka` || tag.slug == `${this.selectedLanguage.toLowerCase()}-zalbi-postapka` || tag.slug == `${this.selectedLanguage.toLowerCase()}-registar-na-predmeti_sudski-odluki`)
            .sort((a,b) => (parseInt(a.description) > parseInt(b.description)) ? 1 : ((parseInt(b.description) > parseInt(a.description)) ? -1 : 0));
        })
      ).subscribe(_ => console.log());
  }

  getNewsPosts(selectedLanguage) {
    this.service.getPostBySlug(`${selectedLanguage.toLowerCase()}-novosti`, selectedLanguage)
      .pipe(
        tap((data: any) => {
          this.newsPosts = data.posts.slice(0, 3);
        })
      ).subscribe(_ => console.log());
  }

  seachZalbi(){
    this.router.navigate(['table/zalbi'])
  }
  searchResenija(){
    this.router.navigate(['table/resenija'])
  }

  changed(event){
    this.selectedLanguage = event.target.value;
    this.storage.addLanguage(this.selectedLanguage)
    window.location.reload()
  }

  seeAllInTable(data: string){
    this.router.navigate([`table/${data}`])
  }

  downloadDecision(filename: string){
    return `${window.location.origin}/api/decisions/attachments/download?filename=${filename}`
  }

  downloadAppeal(filename: string) {
    return `${window.location.origin}/api/appeals/attachments/download?filename=${filename}`;
  }

  downloadAdministrativeCourtDecision(filename: string) {
    return `${window.location.origin}/api/administrativeCourtDecisions/attachments/download?filename=${filename}`;
  }

  downloadHighAdministrativeCourtDecision(filename: string) {
    return `${window.location.origin}/api/highAdministrativeCourtDecisions/attachments/download?filename=${filename}`;
  }
}
