import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomePage} from './pages/home/home.page';
import { TablePage } from './pages/table/table.page';
import {TagPage} from './pages/tag/tag.page';
import {PostPage} from './pages/post/post.page';

const routes: Routes = [
  {
    path: '',
    component: HomePage
  },
  {
    path: 'tag/mk-naslovna',
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    path: 'tag/en-naslovna',
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    path: 'tag/al-naslovna',
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    path: 'tag/:slug',
    component: TagPage
  },
  {
    path: 'post/:id',
    component: PostPage
  },
  {
    path: 'table/:data',
    component: TablePage
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
