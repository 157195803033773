<div class='wrapper'>
  <div class='header d-flex justify-content-between'>
    <div>
      <input type='checkbox' class='openSidebarMenu' id='openSidebarMenu'>
      <div class='d-flex language-options'>
        <select
          name='contactMethod'
          id='contactMethod'
          class='ps-3 language-transparent'
          (change)='changed($event)'
          [(ngModel)]='selectedLanguage'>
          <option class='language-transparent' *ngFor='let l of languages' [value]='l'>{{ l }}</option>
        </select>
      </div>
      <label for='openSidebarMenu' class='sidebarIconToggle'>
        <div class='spinner diagonal part-1'></div>
        <div class='spinner horizontal'></div>
        <div class='spinner diagonal part-2'></div>
      </label>
      <div id='sidebarMenu'>
        <ul class='sidebarMenuInner' *ngFor='let tag of tags'>
          <li *ngIf='tag.slug != this.selectedLanguage.toLowerCase()+"-registar-na-predmeti"'><a
            href='tag/{{tag.slug}}'>{{tag.name}}</a></li>
          <li *ngIf='tag.slug == this.selectedLanguage.toLowerCase()+"-registar-na-predmeti"'><a
            href='table/resenija'>{{tag.name}}</a></li>
        </ul>
      </div>
    </div>
    <img class='logo d-none d-md-inline' src='assets/images/Logo.png'>
  </div>

  <div class='row page'>
    <div class='page-column col-12 col-md-6 padding-default-first'>
      <div class='highlight-post d-flex flex-column text-center align-items-center'>
        <img class='img img-fluid' src='assets/images/GRB_Zlaten_512x512.png'/>
        <div class='m-2 skola-sans'>Република Северна Македонија</div>
        <h5 class='w-50 m-3 skola-sans'>ДРЖАВНА КОМИСИЈА ЗА ЖАЛБИ ПО ЈАВНИ НАБАВКИ</h5>
      </div>
      <hr>
      <div *ngIf='homePost' class='d-flex flex-column text-center align-items-center highlight-text'>
        <h4 class='m-2 fw-bolder'>{{homePost.title}}</h4>
        <div class='w-100 m-4 custom-text-size' [innerHTML]='homePost.html'></div>
        <hr>
      </div>
    </div>
    <div class='col-12 col-md-6 page-column padding-default-second'>
      <div class='frame1'>
        <ul ngbNav #nav1='ngbNav' [(activeId)]='tabs1' class='nav-tabs'>
          <li [ngbNavItem]='1'>
            <a ngbNavLink>Примени жалби</a>
            <ng-template ngbNavContent>
              <table class='table-custom'>
                <tr *ngFor='let a of appeals'>
                  <td>{{a.title}}</td>
                  <td class='text-end'><a class='text-white' [href]='downloadAppeal(a.filename)'>{{a.filename}}</a>
                  </td>
                </tr>
              </table>
              <div class='d-flex justify-content-end mt-2'>
                <button class='btn btn-red' (click)='seeAllInTable("zalbi")'>Прикажи ги сите</button>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]='2'>
            <a ngbNavLink>Решенија на ДКЖЈН</a>
            <ng-template ngbNavContent>
              <table class='table-custom'>
                <tr *ngFor='let d of decisions'>
                  <td>{{d.subject}}</td>
                  <td class='text-end'><a class='text-white' [href]='downloadDecision(d.filename)'>{{d.filename}}</a>
                  </td>
                </tr>
              </table>
              <div class='d-flex justify-content-end mt-2'>
                <button class='btn btn-red' (click)='seeAllInTable("resenija")'>Прикажи ги сите</button>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]='3'>
            <a ngbNavLink>Одлуки на Управен суд</a>
            <ng-template ngbNavContent>
              <table class='table-custom'>
                <tr *ngFor='let a of acd'>
                  <td>{{a.title}}</td>
                  <td class='text-end'><a class='text-white' [href]='downloadAdministrativeCourtDecision(a.filename)'>{{a.filename}}</a>
                  </td>
                </tr>
              </table>
              <div class='d-flex justify-content-end mt-2'>
                <button class='btn btn-red' (click)='seeAllInTable("odlukiUS")'>Прикажи ги сите</button>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]='4'>
            <a ngbNavLink>Одлуки на Виш Управен суд</a>
            <ng-template ngbNavContent>
              <table class='table-custom'>
                <tr *ngFor='let a of hacd'>
                  <td>{{a.title}}</td>
                  <td class='text-end'><a class='text-white' [href]='downloadHighAdministrativeCourtDecision(a.filename)'>{{a.filename}}</a>
                  </td>
                </tr>
              </table>
              <div class='d-flex justify-content-end mt-2'>
                <button class='btn btn-red' (click)='seeAllInTable("odlukiVUS")'>Прикажи ги сите</button>
              </div>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]='nav1' class='mt-2'></div>
      </div>
      <!--      <div class='frame2'>-->
      <!--        <ul ngbNav #nav2='ngbNav' [(activeId)]='tabs2' class='nav-tabs'>-->
      <!--          <li [ngbNavItem]='1'>-->
      <!--            <a ngbNavLink>Жалби</a>-->
      <!--            <ng-template ngbNavContent>-->
      <!--              <form [formGroup]='zalbi' (ngSubmit)='seachZalbi()'>-->
      <!--                <div class='row'>-->
      <!--                  <div class='col-12 col-md-6 p-2 fit-content'>-->
      <!--                    <input class='form-control m-2' type='text' formControlName='datum' placeholder='Година'/>-->
      <!--                    <input class='form-control m-2' type='text' formControlName='postapka'-->
      <!--                           placeholder='Вид на постапка'/>-->
      <!--                  </div>-->
      <!--                  <div class='col-12 col-md-6 p-2 fit-content'>-->
      <!--                    <input class='form-control m-2' type='text' formControlName='dogovorenorgan'-->
      <!--                           placeholder='Договорен орган'/>-->
      <!--                    <input class='form-control m-2' type='text' formControlName='zalitel' placeholder='Жалител'/>-->
      <!--                  </div>-->
      <!--                </div>-->
      <!--                <div class='d-flex justify-content-end mt-2'>-->
      <!--                  <button type='submit' class='btn btn-red'>Пребарај</button>-->
      <!--                </div>-->
      <!--              </form>-->
      <!--            </ng-template>-->
      <!--          </li>-->
      <!--          <li [ngbNavItem]='2'>-->
      <!--            <a ngbNavLink>Решенија</a>-->
      <!--            <ng-template ngbNavContent>-->
      <!--              <form [formGroup]='zalbi' (ngSubmit)='searchResenija()'>-->
      <!--                <div class='row'>-->
      <!--                  <div class='col-12 col-md-6 p-2 fit-content'>-->
      <!--                    <input class='form-control m-2' type='text' formControlName='datum' placeholder='Година'/>-->
      <!--                    <input class='form-control m-2' type='text' formControlName='postapka'-->
      <!--                           placeholder='Вид на постапка'/>-->
      <!--                  </div>-->
      <!--                  <div class='col-12 col-md-6 p-2 fit-content'>-->
      <!--                    <input class='form-control m-2' type='text' formControlName='dogovorenorgan'-->
      <!--                           placeholder='Договорен орган'/>-->
      <!--                    <input class='form-control m-2' type='text' formControlName='zalitel' placeholder='Жалител'/>-->
      <!--                  </div>-->
      <!--                </div>-->
      <!--                <div class='d-flex justify-content-end mt-2'>-->
      <!--                  <button type='submit' class='btn btn-red'>Пребарај</button>-->
      <!--                </div>-->
      <!--              </form>-->
      <!--            </ng-template>-->
      <!--          </li>-->
      <!--        </ul>-->
      <!--        <div [ngbNavOutlet]='nav2' class='mt-2'></div>-->
      <!--      </div>-->
    </div>
  </div>
</div>
<div *ngIf='refTags' class='tags-page row'>
  <div *ngFor='let refTag of refTags' class='col-12 col-md-3 custom-col'>
    <div *ngIf='refTag.slug == this.selectedLanguage.toLowerCase()+"-registar-na-predmeti"'>
      <img class='pointer' routerLink='table/resenija' src='{{refTag.feature_image}}' alt='{{refTag.name}}'>
      <div class='d-flex justify-content-between mt-4'>
        <div class='pointer reftag-title' routerLink='table/resenija'>
          {{refTag.name}}
        </div>
        <div class='pointer' routerLink='table/resenija'>
          <i class='bi bi-arrow-right-short'></i>
        </div>
      </div>
    </div>
    <div *ngIf='refTag.slug != this.selectedLanguage.toLowerCase()+"-registar-na-predmeti"'>
      <img class='pointer' routerLink='tag/{{refTag.slug}}' src='{{refTag.feature_image}}' alt='{{refTag.name}}'>
      <div class='d-flex justify-content-between mt-4'>
        <div class='pointer reftag-title' routerLink='tag/{{refTag.slug}}'>
          {{refTag.name}}
        </div>
        <div class='pointer' routerLink='tag/{{refTag.slug}}'>
          <i class='bi bi-arrow-right-short'></i>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf='newsPosts' class='news-page'>
  <h3 class='text-center primary-color pointer'
      routerLink='tag/{{selectedLanguage.toLowerCase()}}-novosti'>{{newsTag?.name}}</h3>
  <div *ngFor='let np of newsPosts'>
    <div [innerHTML]='np.html'></div>
    <div class='d-flex justify-content-between mt-2'>
      <div class='primary-color pointer' routerLink='post/{{np.id}}'>
        Повеќе <i class='bi bi-arrow-right-short'></i>
      </div>
    </div>
  </div>
</div>
