<div class="header d-flex justify-content-between">
  <div>
    <input type="checkbox" class="openSidebarMenu" id="openSidebarMenu">
    <div class="d-flex language-options">
      <!--        <i class="bi bi-list border icon-padding"></i>-->
      <select
        name="contactMethod"
        id="contactMethod"
        class="ps-3 language-transparent"
        (change)="changed($event)"
        [(ngModel)]="selectedLang">
        <option class="language-transparent"  *ngFor="let l of languages" [value]="l">{{ l }}</option>
      </select>
    </div>
    <label for="openSidebarMenu" class="sidebarIconToggle">
      <div class="spinner diagonal part-1"></div>
      <div class="spinner horizontal"></div>
      <div class="spinner diagonal part-2"></div>
    </label>
    <div id="sidebarMenu">
      <ul class="sidebarMenuInner" *ngFor="let tag of tags">
        <li *ngIf='tag.slug != this.selectedLang.toLowerCase()+"-registar-na-predmeti"'><a href="tag/{{tag.slug}}">{{tag.name}}</a></li>
        <li *ngIf='tag.slug == this.selectedLang.toLowerCase()+"-registar-na-predmeti"'><a href="table/resenija">{{tag.name}}</a></li>
      </ul>
    </div>


  </div>
  <img class="logo" routerLink="" src="assets/images/Logo.png">
</div>