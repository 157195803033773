import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppealResponse } from '../model/appeal-response.interface';

@Injectable()
export class AppealsService {
  baseUrl = `${window.location.origin}/api/appeals/`;

  constructor(private _http: HttpClient) {
  }

  getAppealsPageable(page: number, size: number) {
    return this._http.get<AppealResponse>(`${this.baseUrl}findByFilters?page=${page}&size=${size}`);
  }

  getAppealsFiltered(page: number, size: number, includeContent: boolean, filterParams: any) {
    return this._http.get<AppealResponse>(
      `${this.baseUrl}findByFilters?page=${page}&size=${size}&includeContent=${includeContent}&filterParams=${filterParams}`);
  }

  getAppealsByContent(content: string, page: number, size: number, includeContent: boolean) {
    return this._http.get<AppealResponse>(
      `${this.baseUrl}findByContent?page=${page}&size=${size}&content=${content}&includeContent=${includeContent}`);
  }

  downloadAppealByFilename(filename: string) {
    return this._http.get(`${this.baseUrl}attachments/download?filename=${filename}`, { responseType: 'blob' });
  }

}
