import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import { NgxPaginationModule } from 'ngx-pagination';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HeaderPage} from './pages/layout/header/header.page';
import { TablePage } from './pages/table/table.page';
import { AdministrativeCourtDecisionService } from './services/administrative-court-decisions.service';
import { AppealsService } from './services/appeals.service';
import {ContentService} from './services/content.service';
import {HttpClientModule} from '@angular/common/http';
import {HomePage} from './pages/home/home.page';
import {FooterPage} from './pages/layout/footer/footer.page';
import {TagPage} from './pages/tag/tag.page';
import {PostPage} from './pages/post/post.page';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DecisionsService } from './services/decisions.service';
import { HighAdministrativeCourtDecisionService } from './services/high-administrative-court-decisions.service';
import {StorageService} from './services/storage.service';

const components = [
  AppComponent
];

const pages = [
  HeaderPage,
  HomePage,
  FooterPage,
  TagPage,
  PostPage,
  TablePage
];

const services = [
  ContentService,
  StorageService,
  DecisionsService,
  AppealsService,
  AdministrativeCourtDecisionService,
  HighAdministrativeCourtDecisionService
];

const modules = [
  BrowserModule,
  AppRoutingModule,
  HttpClientModule,
  NgbModule,
  NgxPaginationModule
];

@NgModule({
  declarations: [
    ...components,
    ...pages
  ],
  imports: [
    ...modules,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [...services],
  bootstrap: [AppComponent]
})
export class AppModule {
}
