<app-header></app-header>
<div *ngIf='data == "zalbi"' class='p-5'>
  <h3 class='red-text my-4 p-5'>Жалби</h3>

  <form [formGroup]='appealContentGroup'>
    <div class="row my-4">
      <div class="col-12 col-md-6 p-2 fit-content content-form">
        <input formControlName='content' class="form-control ms-2" type="text" placeholder="Содржина"/>
        <button type='submit' class='btn btn-red' (click)='searchAppealsByContent()'>Пребарај</button>
      </div>
    </div>
  </form>

  <form [formGroup]='appealsGroup'>
    <div class="row my-4">
      <div class="col-12 col-md-6 p-2 fit-content">
        <input formControlName='appellant' class="form-control m-2 custom-input" type="text" placeholder="Жалител"/>
        <input formControlName='filename' class="form-control m-2 custom-input" type="text" placeholder="Име на датотеката"/>
        <input formControlName='title' class="form-control m-2 custom-input" type="text" placeholder="Наслов"/>
      </div>
      <div class="col-12 col-md-6 p-2 fit-content">
        <input formControlName='contractingAuthority' class="form-control m-2 custom-input" type="text" placeholder="Договорен орган"/>
        <input formControlName='dateOfSubmission' class="form-control m-2 custom-input" type="number" placeholder="Датум на поднесување"/>
        <input formControlName='procurementNotice' class="form-control m-2 custom-input" type="text" placeholder="Оглас"/>
      </div>
      <div class='d-flex justify-content-end mt-2'>
        <button type='submit' class='btn btn-red' (click)='searchAppeals()'>Пребарај</button>
      </div>
    </div>
  </form>

  <table class='table table-striped'>
    <tr class='bg-red'>
      <th class='p-5 text-center'>Жалител</th>
      <th class='p-5 text-center'>Договорен Орган</th>
      <th class='p-5 text-center'>Датум на поднесување</th>
      <th class='p-5 text-center'>Оглас</th>
      <th class='p-5 text-center'>Документ</th>
    </tr>
    <tr  *ngFor='let a of appeals | paginate: {
              itemsPerPage: pageSize,
              currentPage: page,
              totalItems: totalItems
            }; let i = index' [ngClass]='i%2 == 0? "gray" : "darker-gray"'>
      <td class='text-center p-5'>{{a.appellant}}</td>
      <td class='text-center p-5'>{{a.contractingAuthority}}</td>
      <td class='text-center p-5'>{{a.dateOfSubmission | date}}</td>
      <td class='text-center p-5'>{{a.procurementNotice}}</td>
      <td class='text-center p-5'><a [href]='downloadAppeal(a.filename)'>Документ</a></td>
    </tr>
  </table>
  <div class='text-end'>
    <pagination-controls (pageChange)="handlePageChange($event)"></pagination-controls>
  </div>
</div>
<div *ngIf='data == "resenija"' class='p-5'>
  <h3 class='red-text my-4 p-5'>Решенија</h3>
    <form [formGroup]='decisionContentGroup'>
      <div class="row my-4">
        <div class="col-12 col-md-6 p-2 fit-content content-form">
          <input formControlName='content' class="form-control ms-2" type="text" placeholder="Содржина"/>
          <button type='submit' class='btn btn-red' (click)='searchDecisionsByContent()'>Пребарај</button>
        </div>
      </div>
    </form>

    <form [formGroup]='decisionsGroup'>
      <div class="row my-4">
      <div class="col-12 col-md-6 p-2 fit-content">
        <input formControlName='year' class="form-control m-2 custom-input" type="number" placeholder="Година"/>
        <input formControlName='contractingAuthority' class="form-control m-2 custom-input" type="text" placeholder="Договорен орган"/>
        <input formControlName='appellant' class="form-control m-2 custom-input" type="text" placeholder="Жалител"/>
        <input formControlName='procedure' class="form-control m-2 custom-input" type="text" placeholder="Вид на постапка"/>
      </div>
      <div class="col-12 col-md-6 p-2 fit-content">
        <input formControlName='subject' class="form-control m-2 custom-input" type="text" placeholder="Предмет"/>
        <input formControlName='decision' class="form-control m-2 custom-input" type="text" placeholder="Решение"/>
        <input formControlName='session' class="form-control m-2 custom-input" type="text" placeholder="Седница"/>
        <input formControlName='procurementNotice' class="form-control m-2 custom-input" type="text" placeholder="Оглас"/>
      </div>
      <div class='d-flex justify-content-end mt-2'>
        <button type='submit' class='btn btn-red' (click)='searchDecisions()'>Пребарај</button>
      </div>
      </div>
    </form>


  <table class='table table-striped'>
    <tr class='bg-red'>
      <th class='p-5 text-center'>Година</th>
      <th class='p-5 text-center'>Дог. орган</th>
      <th class='p-5 text-center'>Жалител</th>
      <th class='p-5 text-center'>Вид на постапка</th>
      <th class='p-5 text-center'>Предмет</th>
      <th class='p-5 text-center'>Решение</th>
      <th class='p-5 text-center'>Седница</th>
      <th class='p-5 text-center'>Оглас</th>
      <th class='p-5 text-center'>Документ</th>
    </tr>
    <tr *ngFor='let d of decisions | paginate: {
              itemsPerPage: pageSize,
              currentPage: page,
              totalItems: totalItems
            }; let i = index' [ngClass]='i%2 == 0? "gray" : "darker-gray"'>
      <td class='text-center p-4'>{{d.year}}</td>
      <td class='text-center p-4'>{{d.contractingAuthority}}</td>
      <td class='text-center p-4'>{{d.appellant}}</td>
      <td class='text-center p-4'>{{d.procedure}}</td>
      <td class='text-center p-4'>{{d.subject}}</td>
      <td class='text-center p-4'>{{d.decision}}</td>
      <td class='text-center p-4'>{{d.session}}</td>
      <td class='text-center p-4'>{{d.procurementNotice}}</td>
      <td class='text-center p-4'><a [href]='downloadDecision(d.filename)'>Документ</a></td>
    </tr>
  </table>
  <div class='text-end'>
    <pagination-controls (pageChange)="handlePageChange($event)"></pagination-controls>
  </div>
</div>
<div *ngIf='data == "odlukiUS"' class='p-5'>
  <h3 class='red-text my-4 p-5'>Одлуки од Управен суд</h3>

  <form [formGroup]='acdContentGroup'>
    <div class="row my-4">
      <div class="col-12 col-md-6 p-2 fit-content content-form">
        <input formControlName='content' class="form-control ms-2" type="text" placeholder="Содржина"/>
        <button type='submit' class='btn btn-red' (click)='searchAcdByContent()'>Пребарај</button>
      </div>
    </div>
  </form>

  <form [formGroup]='acdGroup'>
    <div class="row my-4">
      <div class="col-12 col-md-6 p-2 fit-content">
        <input formControlName='title' class="form-control m-2 custom-input" type="text" placeholder="Наслов"/>

      </div>
      <div class="col-12 col-md-6 p-2 fit-content">
        <input formControlName='year' class="form-control m-2 custom-input" type="number" placeholder="Година"/>
      </div>
      <div class='d-flex justify-content-end mt-2'>
        <button type='submit' class='btn btn-red' (click)='searchAcd()'>Пребарај</button>
      </div>
    </div>
  </form>

  <table class='table table-striped'>
    <tr class='bg-red'>
      <th class='p-5 text-center'>Име на датотеката</th>
      <th class='p-5 text-center'>Наслов</th>
      <th class='p-5 text-center'>Година</th>
      <th class='p-5 text-center'>Документ</th>
    </tr>
    <tr *ngFor='let acd of administrativeCourtDecisions | paginate: {
              itemsPerPage: pageSize,
              currentPage: page,
              totalItems: totalItems
            }; let i = index' [ngClass]='i%2 == 0? "gray" : "darker-gray"'>
      <td class='text-center p-4'>{{acd.filename}}</td>
      <td class='text-center p-4'>{{acd.title}}</td>
      <td class='text-center p-4'>{{acd.year}}</td>
      <td class='text-center p-5'><a [href]='downloadAdministrativeCourtDecision(acd.filename)'>Документ</a></td>
    </tr>
  </table>
  <div class='text-end'>
    <pagination-controls (pageChange)="handlePageChange($event)"></pagination-controls>
  </div>
</div>

<div *ngIf='data == "odlukiVUS"' class='p-5'>
  <h3 class='red-text my-4 p-5'>Одлуки од Виш Управен суд</h3>

  <form [formGroup]='hacdContentGroup'>
    <div class="row my-4">
      <div class="col-12 col-md-6 p-2 fit-content content-form">
        <input formControlName='content' class="form-control ms-2" type="text" placeholder="Содржина"/>
        <button type='submit' class='btn btn-red' (click)='searchHacdByContent()'>Пребарај</button>
      </div>
    </div>
  </form>

  <form [formGroup]='hacdGroup'>
    <div class="row my-4">
      <div class="col-12 col-md-6 p-2 fit-content">
        <input formControlName='title' class="form-control m-2 custom-input" type="text" placeholder="Наслов"/>

      </div>
      <div class="col-12 col-md-6 p-2 fit-content">
        <input formControlName='year' class="form-control m-2 custom-input" type="number" placeholder="Година"/>
      </div>
      <div class='d-flex justify-content-end mt-2'>
        <button type='submit' class='btn btn-red' (click)='searchHacd()'>Пребарај</button>
      </div>
    </div>
  </form>

  <table class='table table-striped'>
    <tr class='bg-red'>
      <th class='p-5 text-center'>Име на датотеката</th>
      <th class='p-5 text-center'>Наслов</th>
      <th class='p-5 text-center'>Година</th>
      <th class='p-5 text-center'>Документ</th>
    </tr>
    <tr *ngFor='let hacd of highAdministrativeCourtDecisions | paginate: {
              itemsPerPage: pageSize,
              currentPage: page,
              totalItems: totalItems
            }; let i = index' [ngClass]='i%2 == 0? "gray" : "darker-gray"'>
      <td class='text-center p-4'>{{hacd.filename}}</td>
      <td class='text-center p-4'>{{hacd.title}}</td>
      <td class='text-center p-4'>{{hacd.year}}</td>
      <td class='text-center p-5'><a [href]='downloadHighAdministrativeCourtDecision(hacd.filename)'>Документ</a></td>
    </tr>
  </table>
  <div class='text-end'>
    <pagination-controls (pageChange)="handlePageChange($event)"></pagination-controls>
  </div>
</div>