import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AdministrativeCourtDecisionResponse } from '../model/administrative-court-decision-response.interface';

@Injectable()
export class AdministrativeCourtDecisionService {
  baseUrl = `${window.location.origin}/api/administrativeCourtDecisions/`;

  constructor(private _http: HttpClient) {
  }

  getAdministrativeCourtDecisionPageable(page: number, size: number) {
    return this._http.get<AdministrativeCourtDecisionResponse>(`${this.baseUrl}findByFilters?page=${page}&size=${size}`);
  }

  getAdministrativeCourtDecisionFiltered(page: number, size: number, includeContent: boolean, filterParams: any) {
    return this._http.get<AdministrativeCourtDecisionResponse>(
      `${this.baseUrl}findByFilters?page=${page}&size=${size}&includeContent=${includeContent}&filterParams=${filterParams}`);
  }

  getAdministrativeCourtDecisionByContent(content: string, page: number, size: number, includeContent: boolean) {
    return this._http.get<AdministrativeCourtDecisionResponse>(
      `${this.baseUrl}findByContent?page=${page}&size=${size}&content=${content}&includeContent=${includeContent}`);
  }

  downloadAdministrativeCourtDecisionByFilename(filename: string) {
    return this._http.get(`${this.baseUrl}attachments/download?filename=${filename}`, { responseType: 'blob' });
  }

}
