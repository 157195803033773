import {Component, OnInit} from '@angular/core';
import {Post} from '../../model/post.interface';
import {ContentService} from '../../services/content.service';
import {ActivatedRoute} from '@angular/router';
import {tap} from 'rxjs/operators';

@Component({
  templateUrl: 'post.page.html',
  styleUrls: ['post.page.scss']
})
export class PostPage implements OnInit {
  post: Post;

  constructor(
    private service: ContentService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    let postId = this.route.snapshot.paramMap.get('id');
    this.service.getPostById(postId)
      .pipe(
        tap(post => {
          this.post = post.posts[0];
        })
      ).subscribe();
  }
}
