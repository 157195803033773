import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HighAdministrativeCourtDecisionResponse } from '../model/high-administrative-court-decision-response.interface';

@Injectable()
export class HighAdministrativeCourtDecisionService {
  baseUrl = `${window.location.origin}/api/highAdministrativeCourtDecisions/`;

  constructor(private _http: HttpClient) {
  }

  getHighAdministrativeCourtDecisionPageable(page: number, size: number) {
    return this._http.get<HighAdministrativeCourtDecisionResponse>(`${this.baseUrl}findByFilters?page=${page}&size=${size}`);
  }

  getHighAdministrativeCourtDecisionFiltered(page: number, size: number, includeContent: boolean, filterParams: any) {
    return this._http.get<HighAdministrativeCourtDecisionResponse>(
      `${this.baseUrl}findByFilters?page=${page}&size=${size}&includeContent=${includeContent}&filterParams=${filterParams}`);
  }

  getHighAdministrativeCourtDecisionByContent(content: string, page: number, size: number, includeContent: boolean) {
    return this._http.get<HighAdministrativeCourtDecisionResponse>(
      `${this.baseUrl}findByContent?page=${page}&size=${size}&content=${content}&includeContent=${includeContent}`);
  }

  downloadHighAdministrativeCourtDecisionByFilename(filename: string) {
    return this._http.get(`${this.baseUrl}attachments/download?filename=${filename}`, { responseType: 'blob' });
  }

}