<app-header></app-header>
<div class="wrapper row">
  <div class="col-12 col-md-4 subtags d-flex justify-content-end" *ngIf="subTags.length">
    <div class="text-start w-75">
      <h3 *ngIf="tag" class="mb-5 pointer"><a class="text-decoration-none black-text"
                                              href="tag/{{tag.slug}}">{{tag.name}}</a></h3>
      <ul class="list-unstyled">
        <div *ngFor="let st of subTags">
          <li class="border-bottom-black p-2">
            <a [ngClass]="selectedTag.slug == st.slug ? 'primary-color' : ''" class="text-decoration-none subtag-link"
               href="tag/{{st.slug}}">{{st.name}}</a>
          </li>
          <div *ngFor="let stl1 of filterSubtagsL1(st.slug)">
            <li class="border-bottom-black p-2 subcategory">
              <a [ngClass]="selectedTag.slug == stl1.slug ? 'subcategory-clicked' : 'subcategory'"
                 class="text-decoration-none subtag-link" href="tag/{{stl1.slug}}">{{stl1.name}}</a>
            </li>
          </div>
        </div>
      </ul>
    </div>
  </div>
  <div class="col-12 col-md-8 tag-desc">
    <div *ngIf="!selectedTag && tag">
      <h4 class="primary-color mb-5">{{tag.name}}</h4>
      <div *ngIf="tagPosts" class="news-page">
        <div *ngFor="let tp of tagPosts">
          <div class="w-75 m-5" [innerHTML]="tp.html"></div>
          <div class="d-flex justify-content-between mt-2">
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="selectedTag">
      <h4 class="primary-color mb-5">{{selectedTag.name}}</h4>
      <div *ngIf="selectedTagPosts" class="news-page fit-content">
        <div *ngFor="let tp of selectedTagPosts">
          <div [innerHTML]="tp.html"></div>
          <div class="d-flex justify-content-between mt-2">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
