import {Component, OnInit} from '@angular/core';
import {Tag} from '../../../model/tag.interface';
import {ContentService} from '../../../services/content.service';
import {tap} from 'rxjs/operators';
import {StorageService} from '../../../services/storage.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: 'header.page.html',
  styleUrls: ['header.page.scss']
})
export class HeaderPage implements OnInit {
  tags: Tag[];
  languages = ["MK", "AL", "EN"]
  selectedLang: string;
  path: string;
  constructor(private service: ContentService,
              private storageService: StorageService,
              private router: Router) {
  }

  ngOnInit() {
    this.path = this.router.url.split('/')[2]
    this.selectedLang = this.storageService.getLanguage();
    this.getTags(this.selectedLang);
  }

  getTags(selectedLang) {
    this.service.getAllTags()
      .pipe(
        tap((data: any) => {
          this.tags = data.tags
            .filter(tag => !tag.slug.includes('_') && tag.slug.startsWith(selectedLang.toLowerCase()+"-"))
            .sort((a,b) => (parseInt(a.description) > parseInt(b.description)) ? 1 : ((parseInt(b.description) > parseInt(a.description)) ? -1 : 0));
        })
      ).subscribe(_ => console.log());
  }

  changed(event){
    this.selectedLang = event.target.value;
    this.router.navigate([`/tag/${this.selectedLang.toLowerCase()}${this.path.slice(2)}`]).then(_ =>     window.location.reload())
    this.storageService.addLanguage(this.selectedLang)
  }
}
