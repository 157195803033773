import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class StorageService {

  constructor() {
  }

  getLanguage() {
    if(!localStorage.getItem('language')){
      this.addLanguage("MK")
    }
    return localStorage.getItem('language');
  }

  addLanguage(language: string) {
    localStorage.setItem('language', language);
  }

}
