import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class ContentService {
  ghostKey = '2ba6ee769d7e7d3e873a8d9e92';
  baseUrl = `https://dkzjn.gov.mk/ghost/api/v3/content/`;

  constructor(
    private _http: HttpClient
  ) {
  }

  getAllTags() {
    return this._http.get(`${this.baseUrl}tags?key=${this.ghostKey}&limit=all&filter=slug:-[mk,en,al]`);
  }

  getPostBySlug(tagslug: string, selectedLanguage: string) {
    return this._http.get<any>(`${this.baseUrl}posts/?key=${this.ghostKey}&include=tags,authors&filter=tags:${tagslug}%2Btags:${selectedLanguage.toLowerCase()}&limit=all`);
  }

  getENPostBySlug(tagslug: string) {
    return this._http.get<any>(`${this.baseUrl}posts/?key=${this.ghostKey}&include=tags,authors&filter=tag:${tagslug}&tag:en&limit=all`);
  }

  getALPostBySlug(tagslug: string) {
    return this._http.get<any>(`${this.baseUrl}posts/?key=${this.ghostKey}&include=tags,authors&filter=tag:${tagslug}&tag:al&limit=all`);
  }

  getPostById(postId): Observable<any> {
    return this._http.get(`${this.baseUrl}posts/${postId}?key=${this.ghostKey}&include=tags,authors&limit=all`);
  }

  getTagBySlug(tagslug: string) {
    return this._http.get<any>(`${this.baseUrl}tags/slug/${tagslug}/?key=${this.ghostKey}&limit=all`);
  }
}
