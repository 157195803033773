import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContentService } from '../../services/content.service';
import { tap } from 'rxjs/operators';
import { Tag } from '../../model/tag.interface';
import { Post } from '../../model/post.interface';
import { StorageService } from '../../services/storage.service';

@Component({
  templateUrl: 'tag.page.html',
  styleUrls: ['tag.page.scss']
})
export class TagPage implements OnInit {
  tag: Tag;
  selectedTag: Tag = null;
  subTags: Tag[];
  subTagsL1: Tag[];
  subTagsL2: Tag[];
  tagPosts: Post[];
  selectedTagPosts: Post[];
  selectedLanguage: string;

  constructor(private route: ActivatedRoute,
              private service: ContentService,
              private storage: StorageService) {
  }

  ngOnInit() {
    this.selectedLanguage = this.storage.getLanguage();
    let tagSlug = this.route.snapshot.paramMap.get('slug').split('_')[0];
    let selectedTagSlug = this.route.snapshot.paramMap.get('slug');
    this.getPosts(tagSlug, this.selectedLanguage);
    this.getSubTags(tagSlug);
    this.getSelectedTagPosts(selectedTagSlug, this.selectedLanguage);
  }

  getPosts(tagSlug, selectedLanguage) {
    this.service.getTagBySlug(tagSlug)
      .pipe(
        tap(tags => {
          this.tag = tags.tags[0];
          this.service.getPostBySlug(tagSlug, selectedLanguage)
            .pipe(
              tap(posts => {
                this.tagPosts = posts.posts;
              })
            ).subscribe();
        })
      ).subscribe();
  }

  getSelectedTagPosts(selectedTagSlug, selectedLanguage) {
    this.service.getTagBySlug(selectedTagSlug)
      .pipe(
        tap(tags => {
          this.selectedTag = tags.tags[0];
          this.service.getPostBySlug(selectedTagSlug, selectedLanguage)
            .pipe(
              tap(posts => {
                this.selectedTagPosts = posts.posts;
              })
            ).subscribe();
        })
      ).subscribe();
  }

  getSubTags(tagSlug) {
    this.service.getAllTags()
      .pipe(
        tap((data: any) => {
          this.subTags = data.tags.filter(tag => tag.slug.startsWith(tagSlug + '_') && tag.slug.split('_').length == 2)
            .sort((a,b) => (parseInt(a.description) > parseInt(b.description)) ? 1 : ((parseInt(b.description) > parseInt(a.description)) ? -1 : 0));
          this.subTagsL1 =
            data.tags.filter(tag => tag.slug.startsWith(tagSlug + '_') && tag.slug.split('_').length == 3)
              .sort((a,b) => (parseInt(a.description) > parseInt(b.description)) ? 1 : ((parseInt(b.description) > parseInt(a.description)) ? -1 : 0));
          this.subTagsL2 =
            (this.subTagsL1 as any).filter(tag => tag.slug.startsWith(tagSlug + '_') && tag.slug.split('_').length == 4)
              .sort((a,b) => (parseInt(a.description) > parseInt(b.description)) ? 1 : ((parseInt(b.description) > parseInt(a.description)) ? -1 : 0));
        })
      ).subscribe();
  }

  filterSubtagsL1(filterSlug) {
    return this.subTagsL1.filter(value => value.slug.startsWith(filterSlug));
  }

  filterSubtagsL2(filterSlug) {
    return this.subTagsL2.filter(value => value.slug.startsWith(filterSlug));
  }
}
