import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AdministrativeCourtDecisionResponse } from '../../model/administrative-court-decision-response.interface';
import { AdministrativeCourtDecision } from '../../model/administrative-court-decision.interface';
import { AppealResponse } from '../../model/appeal-response.interface';
import { Appeal } from '../../model/appeal.interface';
import { DecisionResponse } from '../../model/decision-response.interface';
import { Decision } from '../../model/decision.interface';
import { HighAdministrativeCourtDecisionResponse } from '../../model/high-administrative-court-decision-response.interface';
import { HighAdministrativeCourtDecision } from '../../model/high-administrative-court-decision.interface';
import { AdministrativeCourtDecisionService } from '../../services/administrative-court-decisions.service';
import { AppealsService } from '../../services/appeals.service';
import { DecisionsService } from '../../services/decisions.service';
import { HighAdministrativeCourtDecisionService } from '../../services/high-administrative-court-decisions.service';

@Component({
  templateUrl: 'table.page.html',
  styleUrls: ['table.page.scss']
})
export class TablePage implements OnInit {
  data: string;
  page = 0;
  pageSize = 10;
  totalItems: number;
  decisions: Decision[];
  appeals: Appeal[];
  administrativeCourtDecisions: AdministrativeCourtDecision[];
  highAdministrativeCourtDecisions: HighAdministrativeCourtDecision[];

  decisionsGroup = this._formDefinition;
  decisionContentGroup = this._contentFormDefinition;

  appealsGroup = this._appealsFormDefinition;
  appealContentGroup = this._appealContentFormDefinition;

  acdGroup = this._acdFormDefinition;
  acdContentGroup = this._acdContentFormDefinition;

  hacdGroup = this._hacdFormDefinition;
  hacdContentGroup = this._hacdContentFormDefinition;

  constructor(private route: ActivatedRoute,
              private _decisionService: DecisionsService,
              private _appealsService: AppealsService,
              private _administrativeCourtDecisionService: AdministrativeCourtDecisionService,
              private _highAdministrativeCourtDecisionService: HighAdministrativeCourtDecisionService,
              private _formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.data = this.route.snapshot.paramMap.get('data');
    if (this.data == 'zalbi') {
      this._appealsService.getAppealsPageable(this.page, this.pageSize)
        .subscribe((res: AppealResponse) => {
          this.totalItems = res.totalElements;
          this.appeals = res.content;
        });
    } else if (this.data == 'resenija') {
      this._decisionService.getDecisionsPageable(this.page, this.pageSize)
        .subscribe((res: DecisionResponse) => {
          this.totalItems = res.totalElements;
          this.decisions = res.content;
        });
    } else if (this.data == 'odlukiUS') {
      this._administrativeCourtDecisionService.getAdministrativeCourtDecisionPageable(this.page, this.pageSize)
        .subscribe((res: AdministrativeCourtDecisionResponse) => {
          this.totalItems = res.totalElements;
          this.administrativeCourtDecisions = res.content;
        });
    } else {
      this._highAdministrativeCourtDecisionService.getHighAdministrativeCourtDecisionPageable(this.page, this.pageSize)
        .subscribe((res: HighAdministrativeCourtDecisionResponse) => {
          this.totalItems = res.totalElements;
          this.highAdministrativeCourtDecisions = res.content;
        });
    }
  }

  private get _formDefinition() {
    return this._formBuilder.group({
      year: [null],
      contractingAuthority: [null],
      appellant: [null],
      procedure: [null],
      subject: [null],
      decision: [null],
      session: [null],
      procurementNotice: [null]
    });
  }

  private get _appealsFormDefinition() {
    return this._formBuilder.group({
      filename: [null],
      title: [null],
      content: [null],
      contractingAuthority: [null],
      procurementNotice: [null],
      appellant: [null],
      dateOfSubmission: [null]
    });
  }

  private get _acdFormDefinition() {
    return this._formBuilder.group({
      filename: [null],
      title: [null],
      content: [null],
      year: [null]
    });
  }

  private get _hacdFormDefinition() {
    return this._formBuilder.group({
      filename: [null],
      title: [null],
      content: [null],
      year: [null]
    });
  }

  private get _contentFormDefinition() {
    return this._formBuilder.group({
      content: [null]
    });
  }

  private get _acdContentFormDefinition() {
    return this._formBuilder.group({
      content: [null]
    });
  }

  private get _hacdContentFormDefinition() {
    return this._formBuilder.group({
      content: [null]
    });
  }

  private get _appealContentFormDefinition() {
    return this._formBuilder.group({
      content: [null]
    });
  }

  handlePageChange(event) {
    this.page = event;
    this.reloadData();
  }

  reloadData() {
    this.data = this.route.snapshot.paramMap.get('data');
    if (this.data == 'zalbi') {
      if (this.appealContentGroup.get("content").value != "" && this.appealContentGroup.get("content").value != null) {
        this._appealsService.getAppealsByContent(this.appealContentGroup.get("content").value, this.page - 1, this.pageSize, false)
          .subscribe((res: AppealResponse) => {
            this.totalItems = res.totalElements;
            this.appeals = res.content;
            window.scrollTo({ top: 0, behavior: 'smooth' });
          })
      } else if (Object.keys(this.appealsGroup.value).some(k => !!this.appealsGroup.value[k])) {
        let o = this.appealsGroup.value;
        Object.keys(o).forEach((k) => o[k] == null && delete o[k]);
        this._appealsService.getAppealsFiltered(this.page - 1, this.pageSize, true, encodeURI(JSON.stringify(o)))
          .subscribe((res: AppealResponse) => {
            this.totalItems = res.totalElements;
            this.appeals = res.content;
            window.scrollTo({ top: 0, behavior: 'smooth' });
          })
      } else {
        this._appealsService.getAppealsPageable(this.page - 1, this.pageSize)
          .subscribe((res: AppealResponse) => {
            this.totalItems = res.totalElements;
            this.appeals = res.content;
            window.scrollTo({ top: 0, behavior: 'smooth' });
          });
      }
    } else if (this.data == 'resenija') {
      if (this.decisionContentGroup.get("content").value != "" && this.decisionContentGroup.get("content").value != null) {
        this._decisionService.getDecisionsByContent(this.decisionContentGroup.get("content").value, this.page - 1, this.pageSize, false)
          .subscribe((res: DecisionResponse) => {
            this.totalItems = res.totalElements;
            this.decisions = res.content;
            window.scrollTo({ top: 0, behavior: 'smooth' });
          })
      } else if (Object.keys(this.decisionsGroup.value).some(k => !!this.decisionsGroup.value[k])) {
        let o = this.decisionsGroup.value;
        Object.keys(o).forEach((k) => o[k] == null && delete o[k]);
        this._decisionService.getDecisionsFiltered(this.page - 1, this.pageSize, true, encodeURI(JSON.stringify(o)))
          .subscribe((res: DecisionResponse) => {
            this.totalItems = res.totalElements;
            this.decisions = res.content;
            window.scrollTo({ top: 0, behavior: 'smooth' });
          })
      } else {
        this._decisionService.getDecisionsPageable(this.page - 1, this.pageSize)
          .subscribe((res: DecisionResponse) => {
            this.totalItems = res.totalElements;
            this.decisions = res.content;
            window.scrollTo({ top: 0, behavior: 'smooth' });
          });
      }
    } else if (this.data == 'odlukiUS') {
      if (this.acdContentGroup.get("content").value != "" && this.acdContentGroup.get("content").value != null) {
        this._administrativeCourtDecisionService.getAdministrativeCourtDecisionByContent(this.acdContentGroup.get("content").value, this.page - 1, this.pageSize, false)
          .subscribe((res: AdministrativeCourtDecisionResponse) => {
            this.totalItems = res.totalElements;
            this.administrativeCourtDecisions = res.content;
            window.scrollTo({ top: 0, behavior: 'smooth' });
          })
      } else if(Object.keys(this.acdGroup.value).some(k => !!this.acdGroup.value[k])) {
        let o = this.acdGroup.value;
        Object.keys(o).forEach((k) => o[k] == null && delete o[k]);
        this._administrativeCourtDecisionService.getAdministrativeCourtDecisionFiltered(this.page - 1, this.pageSize, true, encodeURI(JSON.stringify(o)))
          .subscribe((res: AdministrativeCourtDecisionResponse) => {
            this.totalItems = res.totalElements;
            this.administrativeCourtDecisions = res.content;
            window.scrollTo({ top: 0, behavior: 'smooth' });
          })
      } else {
        this._administrativeCourtDecisionService.getAdministrativeCourtDecisionPageable(this.page - 1, this.pageSize)
          .subscribe((res: AdministrativeCourtDecisionResponse) => {
            this.totalItems = res.totalElements;
            this.administrativeCourtDecisions = res.content;
            window.scrollTo({ top: 0, behavior: 'smooth' });
          });
      }
    } else {
      if (this.hacdContentGroup.get("content").value != "" && this.hacdContentGroup.get("content").value != null) {
        this._highAdministrativeCourtDecisionService.getHighAdministrativeCourtDecisionByContent(this.hacdContentGroup.get("content").value, this.page - 1, this.pageSize, false)
          .subscribe((res: HighAdministrativeCourtDecisionResponse) => {
            this.totalItems = res.totalElements;
            this.highAdministrativeCourtDecisions = res.content;
          })
      } else if (Object.keys(this.hacdGroup.value).some(k => !!this.hacdGroup.value[k])) {
        let o = this.hacdGroup.value;
        Object.keys(o).forEach((k) => o[k] == null && delete o[k]);
        this._highAdministrativeCourtDecisionService.getHighAdministrativeCourtDecisionFiltered(this.page - 1, this.pageSize, true, encodeURI(JSON.stringify(o)))
          .subscribe((res: HighAdministrativeCourtDecisionResponse) => {
            this.totalItems = res.totalElements;
            this.highAdministrativeCourtDecisions = res.content;
          })
      } else {
        this._highAdministrativeCourtDecisionService.getHighAdministrativeCourtDecisionPageable(this.page - 1, this.pageSize)
          .subscribe((res: HighAdministrativeCourtDecisionResponse) => {
            this.totalItems = res.totalElements;
            this.highAdministrativeCourtDecisions = res.content;
            window.scrollTo({ top: 0, behavior: 'smooth' });
          });
      }
    }
  }

  searchDecisions(){
    let o = this.decisionsGroup.value;
    Object.keys(o).forEach((k) => o[k] == null && delete o[k]);
    this.page = 0;
    this.pageSize = 10;
    this._decisionService.getDecisionsFiltered(this.page, this.pageSize, true, encodeURI(JSON.stringify(o)))
      .subscribe((res: DecisionResponse) => {
        this.totalItems = res.totalElements;
        this.decisions = res.content;
      })
  }

  searchAppeals(){
    let o = this.appealsGroup.value;
    Object.keys(o).forEach((k) => o[k] == null && delete o[k]);
    this.page = 0;
    this.pageSize = 10;
    this._appealsService.getAppealsFiltered(this.page, this.pageSize, true, encodeURI(JSON.stringify(o)))
      .subscribe((res: AppealResponse) => {
        this.totalItems = res.totalElements;
        this.appeals = res.content;
      })
  }

  searchAcd(){
    let o = this.acdGroup.value;
    Object.keys(o).forEach((k) => o[k] == null && delete o[k]);
    this.page = 0;
    this.pageSize = 10;
    this._administrativeCourtDecisionService.getAdministrativeCourtDecisionFiltered(this.page, this.pageSize, true, encodeURI(JSON.stringify(o)))
      .subscribe((res: AdministrativeCourtDecisionResponse) => {
        this.totalItems = res.totalElements;
        this.administrativeCourtDecisions = res.content;
      })
  }

  searchHacd() {
    let o = this.hacdGroup.value;
    Object.keys(o).forEach((k) => o[k] == null && delete o[k]);
    this.page = 0;
    this.pageSize = 10;
    this._highAdministrativeCourtDecisionService.getHighAdministrativeCourtDecisionFiltered(this.page, this.pageSize, true, encodeURI(JSON.stringify(o)))
      .subscribe((res: HighAdministrativeCourtDecisionResponse) => {
        this.totalItems = res.totalElements;
        this.highAdministrativeCourtDecisions = res.content;
      })
  }

  searchDecisionsByContent(){
    this.page = 0;
    this.pageSize = 10;
    this._decisionService.getDecisionsByContent(this.decisionContentGroup.get("content").value, this.page, this.pageSize, false)
      .subscribe((res: DecisionResponse) => {
        this.totalItems = res.totalElements;
        this.decisions = res.content;
      })
  }

  searchAppealsByContent(){
    this.page = 0;
    this.pageSize = 10;
    this._appealsService.getAppealsByContent(this.appealContentGroup.get("content").value, this.page, this.pageSize, false)
      .subscribe((res: AppealResponse) => {
        this.totalItems = res.totalElements;
        this.appeals = res.content;
      })
  }

  searchAcdByContent(){
    this.page = 0;
    this.pageSize = 10;
    this._administrativeCourtDecisionService.getAdministrativeCourtDecisionByContent(this.acdContentGroup.get("content").value, this.page, this.pageSize, false)
      .subscribe((res: AdministrativeCourtDecisionResponse) => {
        this.totalItems = res.totalElements;
        this.administrativeCourtDecisions = res.content;
      })
  }

  searchHacdByContent(){
    this.page = 0;
    this.pageSize = 10;
    this._highAdministrativeCourtDecisionService.getHighAdministrativeCourtDecisionByContent(this.hacdContentGroup.get("content").value, this.page, this.pageSize, false)
      .subscribe((res: HighAdministrativeCourtDecisionResponse) => {
        this.totalItems = res.totalElements;
        this.highAdministrativeCourtDecisions = res.content;
      })
  }

  downloadDecision(filename: string) {
    return `${window.location.origin}/api/decisions/attachments/download?filename=${filename}`;
  }

  downloadAppeal(filename: string) {
    return `${window.location.origin}/api/appeals/attachments/download?filename=${filename}`;
  }

  downloadAdministrativeCourtDecision(filename: string) {
    return `${window.location.origin}/api/administrativeCourtDecisions/attachments/download?filename=${filename}`;
  }

  downloadHighAdministrativeCourtDecision(filename: string) {
    return `${window.location.origin}/api/highAdministrativeCourtDecisions/attachments/download?filename=${filename}`;
  }
}