<div class="wrapper">
  <div class="row">
    <div class="col-12 col-md-3 m-auto">
      Државна комисија за жалби по јавни набавки
    </div>
    <div class="col-12 col-md-3 m-auto d-flex flex-column">
      <div>Бул. Кирил и Методиј бр. 54 кат 3</div>
      <div>1000 Скопје, Република Северна Македонија</div>
    </div>
    <div class="col-12 col-md-2 m-auto d-flex flex-column">
      <div>Тел. 02 3251 260</div>
      <div>Факс 02 3251 269</div>
      <div>e-mail: dkzjn@gs.gov.mk</div>
    </div>
    <div class="col-12 col-md-2 m-auto d-flex flex-column">
      <div>КОНТАКТ ЗА СТРАНКИ</div>
      <div>секој работен ден во период од 12:30 до 15:00 часот</div>
    </div>
    <div class="col-12 col-md-2 m-auto d-flex flex-column text-center">
      <img class="image" src="assets/images/Proba-MKC-EN-ISO-9001.png" />
    </div>
  </div>
</div>
